import React, { useState } from 'react';
import { Link } from 'gatsby';
import Layout from "../components/layout/Layout"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Stack, Typography } from "@mui/material"
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { PaperClipIcon } from '@heroicons/react/solid'
import axios from 'axios';

export default function JobDetails({ pageContext }) {
  const { position } = pageContext;
  const [showForm, setShowForm] = useState(false);
  const [resumeFileUrl, setResumeFileUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleApplyNow = () => {
    setShowForm(true);
    // Scroll to the form
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'tz6z3ghw'); //

    try {
      setIsUploading(true);
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/durti17dk/upload',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      const { secure_url } = response.data;
      setResumeFileUrl(secure_url);
      setIsUploading(false);
    } catch (error) {
      console.error(error);
      setIsUploading(false);
      // Handle upload error
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    // Get form field values
    const firstName = e.target.elements.firstName.value;
    const middleName = e.target.elements.middleName.value;
    const lastName = e.target.elements.lastName.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
  
    // Construct the email subject and body
    const subject = `Applying for ${position.title}`;
    const body = `First Name: ${firstName}\nMiddle Name: ${middleName}\nLast Name: ${lastName}\nEmail: ${email}\nPhone: ${phone}\nResume: ${resumeFileUrl}`;
  
    // Compose the mailto URL
    const mailtoUrl = `mailto:careers@c4scale.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    // Open the default email client
    window.location.href = mailtoUrl;
  };
  

  return (
    <Layout>
      <Stack sx={{ background: "rgb(215, 233, 255)", padding:{xs:"20px",sm:"20px 40px",md:"20px 90px"}, gap: "6px", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
        <Stack>
          <h1 className="text-2xl  font-[600] text-indigo-600 " style={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "20px" }}>
            <Link to="/careers" style={{ display: "flex" }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "24px", fontWeight: "bold", marginTop: "3px" }} />
            </Link>{position.category}
          </h1>
          <h1 className="text-xl sm:text-2xl font-medium">{position.title}</h1>
          <h1 className="text-lg sm:text-xl font-medium" style={{ display: "flex", alignItems: "center" }}>
            <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" /> {position.location}
          </h1>
          <h1 className="text-lg sm:text-xl font-medium">{position.type} {position.duration && <small> - {position.duration} </small>}</h1>
        </Stack>
        <Stack>
          {!showForm && (
            <button
              onClick={handleApplyNow}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white"
            >
              Apply Now
            </button>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ padding:{xs:"20px",sm:"20px 40px",md:"20px 90px"}, gap: "30px" }}>
        {position.description && (
          <div>
            <h1 className="text-2xl font-[600] text-indigo-600 mb-2">About the Role:</h1>
            <div dangerouslySetInnerHTML={{ __html: position.description }} />
          </div>
        )}
        
        {position.responsibilities && (
          <div>
            <h1 className="text-2xl font-[600] text-indigo-600 mb-2 ">What You Will Do:</h1>
            <div dangerouslySetInnerHTML={{ __html: position.responsibilities }} />
          </div>
        )}
        {position.skillset && (
          <div>
            <h1 className="text-2xl font-[600] text-indigo-600 mb-2 ">What You Will Need:</h1>
            <div dangerouslySetInnerHTML={{ __html: position.skillset }} />
          </div>
        )}
        {position.requirements && (
          <div>
            <h1 className="text-2xl font-[600] text-indigo-600 mb-2 ">Preferred Qualifications / Added Advantage:</h1>
            <div dangerouslySetInnerHTML={{ __html: position.requirements }} />
          </div>
        )}

        {position.offer && (
          <div>
            <h1 className="text-2xl font-[600] text-indigo-600 mb-2 ">What We Offer:</h1>
            <div dangerouslySetInnerHTML={{ __html: position.offer }} />
          </div>
        )}
        {showForm && (
          <form onSubmit={handleFormSubmit} className="min-w-[70vw] mx-auto rounded-lg p-5 sm:p-10" style={{boxShadow: "rgba(0, 0, 0, 0.2) 3px 0px 10px -2px",
            background: "rgb(255, 255, 255)"}}>
            <h1 className="text-2xl font-medium text-indigo-600 mb-4">Submit Your Application</h1>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="firstName" className="block mb-1">First Name *</label>
                <input type="text" id="firstName" name="firstName" required className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
              <div className="mb-4">
                <label htmlFor="middleName" className="block mb-1">Middle Name</label>
                <input type="text" id="middleName" name="middleName" className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
              <div className="mb-4">
                <label htmlFor="lastName" className="block mb-1">Last Name *</label>
                <input type="text" id="lastName" name="lastName" required className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1">Email *</label>
                <input type="email" id="email" name="email" required className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block mb-1">Phone Number</label>
                <input type="tel" id="phone" name="phone" className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
              <div className=" mb-4">
                <label htmlFor="resume" className="block mb-1">Resume *</label>
                <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" required onChange={handleResumeUpload} className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-600" />
              </div>
             
            </div>
            <Stack gap="15px" direction="row" >
            <button type="submit" className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700">
              {isUploading ? (
                'Uploading resume...'
              ) : (
                'Submit'
              )}
            </button>

            {position.link && (
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <a href={position.link} target="_blank" rel="noopener noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
                <div className="flex-1 flex items-center">
                  <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-2 flex-1">Apply via LinkedIn</span>
                </div>
              </a>
              </li>
            </ul>
            )}
            {position.google && (
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <a href={position.google} target="_blank" rel="noopener noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
                  <div className="flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1">Apply via Google Form</span>
                  </div>
                </a>
                </li>
              </ul>
              )}
          </Stack>
            
          </form>
          
          
          
        )}
      </Stack>
    </Layout>
  );
}
